<!--TitleComponent-->
<template>
  <div class="flex items-end mb-40 mt-[3rem]">
    <div class="flex-1 flex flex-col gap-8">
      <div v-if="navItems && navItems?.length > 0" class="flex gap-[.6rem] items-center">
        <template v-for="(navItem, index) in navItems" :key="index">
          <span
            class="text-text4 text-on-surface-elevation-3 font-regular leading-text4"
            :class="{ 'cursor-pointer': !!navItem.url }"
            @click="onClick(navItem)"
          >
            {{ navItem.name }}
          </span>
          <s-icon
            v-if="navItems[index + 1]"
            class="text-on-surface-elevation-3"
            icon="ic-v2-control-arrow-right-line"
            size="xs"
          />
        </template>
      </div>
      <h2 class="font-bold text-5xl leading-4xl text-on-surface-elevation-1 break-all">
        {{ title }}
      </h2>
      <p
        v-if="titleDesc && titleDesc.length > 0"
        class="-mt-4 text-xl leading-xl font-regular text-on-surface-elevation-3"
      >
        {{ titleDesc }}
      </p>
      <div v-if="hasSlot('join-info')" class="flex items-center gap-8 mt-[.2rem]">
        <slot name="join-info"></slot>
      </div>
    </div>
    <div v-if="hasSlot('default')" class="flex gap-12">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useSlots } from 'vue';

import { useUserStore } from '@/stores/user.store';
import { redirectTo } from '@/utils/common.util';

const emit = defineEmits<{
  onClick: [v?: { name: string; url?: string }];
}>();

const userStore = useUserStore();
const { selectedGroupId } = storeToRefs(userStore);
const slots = useSlots();

const hasSlot = (name: string) => !!slots[name];

const onClick = (navItem: { name: string; url?: string }) => {
  if (!navItem.url) {
    return;
  }
  let url = `/${navItem.url}`;
  if (navItem.url !== 'my-info') {
    url = `/${selectedGroupId.value}` + url;
  }
  emit('onClick', navItem);
  redirectTo(url);
};

defineProps<{
  title: string;
  titleDesc?: string;
  navItems?: { name: string; url?: string }[];
}>();
</script>
